import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import tinycolor from "tinycolor2"

import Logo from "./icons/Logo"
import Divider from "./Divider"

const HeaderLayoutContainer = styled.div`
  margin: 0 auto;
  max-width: 920px;
  padding: 0px ${({ theme }) => theme.space[3]} ${({ theme }) => theme.space[2]};

  padding-top: 5rem;
  padding-top: 0px;
  padding-bottom: 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 4rem;
`

const BannerNotification = styled.div`
  text-align: center;
  background: ${({ theme }) => theme.colors.primary};

  color: ${({ theme }) =>
    tinycolor(theme.colors.primary)
      .darken(55)
      .toHexString()};
  font-weight: 600;
  text-transform: uppercase;
  padding: 0.25em 0;
`
const HeaderContainer = styled.div`
  position: -webkit-sticky;
  position: sticky;
  top: 0px;
  background: ${({ theme }) => theme.colors.headerBackground};
  z-index: 1000;
`
const NavLinkContainer = styled(Link)`
  text-transform: uppercase;
  margin-left: ${({ theme }) => theme.space[1]};
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.1em;
  opacity: 1;
  text-decoration: none;
  font-weight: 500;
  color: ${({ theme }) =>
    tinycolor(theme.colors.text)
      .brighten(35)
      .toHexString()};

  &.lm--active-nav--link {
    color: ${({ theme }) => theme.colors.text} !important;
  }
  span {
    padding: ${({ theme }) => theme.space[2]};
    border-radius: ${({ theme }) => theme.radii[2]};
  }
  span:hover {
    background-color: ${({ theme }) => theme.colors.cardBackground};
  }
`
const NavLink = ({ children, to, partiallyActive }) => {
  return (
    <NavLinkContainer
      activeClassName="lm--active-nav--link"
      to={to}
      partiallyActive={partiallyActive}
    >
      <span>{children}</span>
    </NavLinkContainer>
  )
}

const Header = () => (
  <HeaderContainer>
    <HeaderLayoutContainer>
      <Link to="/">
        <Logo />
      </Link>
      <div>
        <NavLink to="/">Home</NavLink>
        <NavLink partiallyActive to="/projects">
          Projects
        </NavLink>
        <NavLink partiallyActive to="/blog">
          Blog
        </NavLink>
      </div>
    </HeaderLayoutContainer>
    <Divider mb={0} />
    {/* <a href="/gateway-gis/" style={{textDecoration: "none"}}>
      <BannerNotification>Gateway GIS Presentation →</BannerNotification>
    </a> */}
  </HeaderContainer>
)

export default Header
